<template>
	<div class="page">
		<div class="home">
			<div class="home__head container">
				<icon icon="v2-home-logo" colour="transparent" />

				<div class="home__head--title">
					Welcome to Bridge128, the <br /> new home for learning Bridge
				</div>
				<div class="home__head--subtitle">
					Learn the world's best card-game from level '1 to 8' with <br /> structured courses on bidding and play.
				</div>

				<V2Button variant="red" to="/browse-courses" class="btn btn-red">
					Find courses
				</V2Button>
				<div class="home__head--images">
					<img src="@/assets/images/home-table2.svg" alt="table" />
				</div>

				<div class="home__head--images-mini">
					<img src="@/assets/images/levels-mini.svg" alt="table" />
					<img src="@/assets/images/home-table-mini.svg" alt="table" />
				</div>
			</div>

			<div class="home__body">
				<div class="container">
					<div class="home__body--registration">
						<div class="reg-card">
							<div class="reg-card__head">
								<div class="reg-card__head--title">
									For students
								</div>
								<div class="reg-card__head--subtitle">
									Bridge128 has eight learner levels – 1 to 8 – from absolute beginner to advanced levels. Take courses on bidding, play and defence with the bidding system of your choice.
								</div>
								<V2Button variant="red" to="/login" class="btn btn-red">
									Sign up today
								</V2Button>
							</div>
							<img src="@/assets/images/reg-card-student.png" />
						</div>

						<div class="reg-card">
							<div class="reg-card__head">
								<div class="reg-card__head--title">
									For teachers
								</div>
								<div class="reg-card__head--subtitle">
									Bridge128 is looking for the best bridge teachers from all over the world. Upload and create content teaching your bidding system, your way, for your students.
								</div>
								<V2Button to="/about/teaching/how-to-become-a-teacher-on-bridge128" class="btn btn-blue">
									Become a teacher
								</V2Button>
							</div>
							<img src="@/assets/images/reg-card-teacher.png" />
						</div>
					</div>

					<div class="home__body--play">
						<div class="play-banner">
							<div class="play-banner__left">
								<img src="@/assets/images/play-cards.png" alt="cards" />
							</div>
							<div class="play-banner__right">
								<div class="play-banner__right--title">
									Play Handle: a daily deal to <br /> test your card-play
								</div>
								<div class="play-banner__right--subtitle">
									No knowledge required - play for free for 30 days!
								</div>
								<div class="btn btn-red" @click="openHandle">
									Play Handle
								</div>
							</div>
						</div>
					</div>

					<div class="home__body--learn">
						<div class="learn">
							<div class="learn--title">
								Learning Bridge has never been easier
							</div>
							<div class="learn--subtitle">
								With your own dedicated dashboard, My Learning, to track progress
							</div>
							<div class="learn--cards">

								<div class="learn--cards-item">
									<div class="learn--cards-item__head">
										<img src="@/assets/images/learn-1.png" />
									</div>
									<div class="learn--cards-item__body">
										<div class="learn--cards-item__body--title">
											Short videos, quizzes and study deals for practice
										</div>
										<div class="learn--cards-item__body--subtitle">
											Play against robots with hints, tips and a run-through of each deal.
										</div>
										<router-link to="/browse-courses" class="learn--cards-item__body--footer">
											Take your first course
											<icon class="chevron" icon="v2-chevron-left" colour="transparent" />
										</router-link>
									</div>
								</div>

								<div class="learn--cards-item">
									<div class="learn--cards-item__head">
										<img src="@/assets/images/learn-2.png" />
									</div>
									<div class="learn--cards-item__body">
										<div class="learn--cards-item__body--title">
											Take live lessons or study at your own pace
										</div>
										<div class="learn--cards-item__body--subtitle">
											Live and recorded courses so that you can choose your learning path.
										</div>
										<router-link to="/browse-courses" class="learn--cards-item__body--footer">
											Find a Live course
											<icon class="chevron" icon="v2-chevron-left" colour="transparent" />
										</router-link>
									</div>
								</div>

								<div class="learn--cards-item">
									<div class="learn--cards-item__head">
										<img src="@/assets/images/learn-3.png" />
									</div>
									<div class="learn--cards-item__body">
										<div class="learn--cards-item__body--title">
											Download course hand-outs to aid your progress
										</div>
										<div class="learn--cards-item__body--subtitle">
											Get detailed PDF documents for every skill and technique to help you along the way.
										</div>
										<router-link to="/about/learning/course-hand-outs" class="learn--cards-item__body--footer">
											Sign up today
											<icon class="chevron" icon="v2-chevron-left" colour="transparent" />
										</router-link>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>

			</div>

			<div class="home__footer container">
				<div class="home__footer__start">
					<div class="start">
						<div class="start__left">
							<div class="start__left--title">
								Start learning Bridge online today, <br /> for FREE!
							</div>
							<div class="start__left--subtitle">
								Bridge128 is dedicated to creating engaging and intuitive Bridge <br /> courses, for every experience level.
							</div>
							<div class="start__left--info">
								<div class="start__left--info-item">
									<icon icon="v2-start-video" colour="trasparet" />
									Videos and quizzes on all areas of the game
								</div>
								<div class="start__left--info-item">
									<icon icon="v2-start-star" colour="trasparet" />
									Practice deals and solutions to hone your card-play
								</div>
								<div class="start__left--info-item">
									<icon icon="v2-start-fire" colour="trasparet" />
									Play Handle for the daily deal and to up your rating
								</div>
								<div class="start__left--info-item">
									<icon icon="v2-start-cards" colour="trasparet" />
									We cover it all - from zero to hero, from level 1 to 8
								</div>
							</div>
							<V2Button variant="red" to="/browse-courses" class="btn btn-red">
								Find courses
							</V2Button>
						</div>
						<div class="start__right">
							<img src="@/assets/images/start.png" alt="" />
						</div>
					</div>

				</div>

				<div class="home__footer__questions">
					<div class="questions">
						<div class="questions__title">
							Frequently Asked Questions
						</div>

						<div class="questions__list">
							<div v-for="(question, idx) in questions" :key="question.title" class="questions__list--item">
								<div class="questions__list--item--head" @click="switchQuestionState(question)">
									{{ question.title }}
									<span>{{ question.isOpen ? '-' : '+' }}</span>
								</div>

								<transition name="slide">
									<div class="questions__list--item--body" v-if="question.isOpen">
										{{ question.answer }}
									</div>
								</transition>

								<div v-if="idx !== questions.length - 1" class="spacer" />
							</div>
						</div>
					</div>
				</div>

				<div class="home__footer__quote">
					<div class="home__footer__quote--text">
						“Many games provide fun, but <span class="bold">Bridge grips you.</span> It exercises your mind. Your mind can rust, you know, but <span class="bold">Bridge prevents the rust from forming.</span>”
						<br /> <br /> - Omar Sharif
					</div>
					<icon class="home__footer__quote--logo" icon="v2-background-logo" colour="transparent" />
				</div>
			</div>
		</div>
	</div>
</template>

<route>
	{
		"meta": {
			"isPublic": true
		}
	}
</route>

<script>
	import Icon             from '@/components/ui/Icon';
	import V2Button from '@/components/v2/ui/V2Button.vue';

	export default {
		layout: 'v2default',

		components: {
			Icon,
			V2Button
		},

		data () {
			return {
				questions: [
					{
						title: 'Can I Play HANDLE if I don’t know how to play bridge?',
						answer: 'Yes! Though bridge takes time to learn - especially the bidding, Handle tests you on card-play only. There’s a walk-thru of the play rules and you’ll be off and running in seconds, with guidance and hints along the way. Then if you want to improve your Handle play - or get stuck in to bridge itself (BTW, fabulous idea!) - take free lessons on Bridge128 and watch your rating soar.',
						isOpen: true
					},
					{
						title: 'How do the HANDLE levels work? What level am I?',
						answer: 'The ‘1 to 8’ levels of Handle correspond to the learning levels of Bridge128. Level 1 is for complete beginners with no knowledge. Once you know the basic rules of bidding and play you are a Level 2 beginner. Levels 3 & 4 are improver, levels 5 & 6 intermediate and levels 7 & 8 advanced. Handle is aimed at ALL players below tournament standard (Level 9), testing technique and knowledge of bridge odds. Find out more about levels under the About tab of Bridge128.',
						isOpen: false
					},
					{
						title: 'How can I play HANDLE on my own when bridge is a game for 4 players?',
						answer: ' Bridge is always played by two players against two but during the play phase in bridge, i.e. as in Handle, one player i.e. you – known as ‘declarer’, plays both your own cards and your partner’s cards. Your two opponents – known as ‘defenders’ - are robot players, Lefty & Righty, whose job it is to defeat you. They have a knack of playing the right card at the right time, but best technique will always get the better of them.',
						isOpen: false
					},
					{
						title: 'Are HANDLE deals random or fixed?',
						answer: 'Fixed. Handle deals are carefully curated, with each deal of the week set to a difficulty rating. Sunday is beginner day, through to Saturday, getting trickier as the week goes on. But importantly, the daily rating is set to the minimum required tricks, so more experienced players can still test their skills - and can boost their ranking - by stretching for overtricks on easier deals, making every day of the week a challenge.',
						isOpen: false
					}
				]
			};
		},

		created () {
			this.$store.commit('ui/clearBreadcrumbs');
			this.$store.commit('ui/setLoadingIsHidden');
		},

		methods: {
			switchQuestionState (question) {
				question.isOpen = !question.isOpen;
			},

			async openHandle () {
				console.log(this.$store.getters['auth/getIsAuthenticated']);
				await this.$store.dispatch('gameEngine/openHandle', { isAuth: this.$store.getters['auth/getIsAuthenticated'] });
			}
		}
	};

</script>

<style lang="scss" scoped>

.slide-enter-active, .slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter, .slide-leave-to {
  max-height: 0;
  opacity: 0;
  padding: 0;
}

.slide-enter-to, .slide-leave {
  max-height: 1000px;
  opacity: 1;
}

.btn {
  cursor: pointer;
  padding: 10px 20px;
  @include font(18px, $c-white-l, 500, 23px);
  border-radius: 4px;
  width: auto !important;

  &-red {
    background: $c-red;
  }

  &-blue {
    background: $c-dark-navy;
  }
}

.home {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__head {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;

    &--title {
      margin-top: 24px;
      @include font(48px, $c-darkest-navy, 500, 62px);
    }

    &--subtitle {
      margin-top: 12px;
      @include font(18px, $c-dark-navy, 400, 23px);
    }

    .btn {
      margin-top: 24px;
    }

    &--images {
      height: 470px;
      width: 100%;
      position: relative;
      overflow: hidden;

      @media (max-width: 1279px) {
        display: none;
      }
    }

    &--images-mini {
      display: none;
      flex-direction: column;
      margin-top: 24px;
      gap: 40px;

      @media (max-width: 1279px) {
        display: flex;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 90px 0;
    background: $c-gray-l;

    &--registration {
      display: flex;
      gap: 24px;

      @media (max-width: 1279px) {
        flex-direction: column;
      }

      .reg-card {
        display: flex;
        flex-direction: column;
        border-radius: 4px;

        &__head {
          background: $c-white-l;
          padding: 32px;

          &--title {
            @include font(28px,$c-darkest-navy, 500, 36px);
          }

          &--subtitle {
            margin-top: 16px;
            @include font(18px,$c-dark-navy, 400, 23px);
          }

          .btn {
            margin-top: 24px;
            width: fit-content;
          }

        }
      }
    }

    &--play {
      margin-top: 120px;
      width: 100%;

      .play-banner {
        display: flex;
        border-radius: 4px;
        background: $c-dark-navy;
        height: 270px;
        position: relative;

        @media (max-width: 1279px) {
          overflow: hidden;
          padding: 24px;
        }

        &__left {
          position: relative;
          width: 332px;
          height: 100%;
          margin: 0 64px 0 60px;

          img {
            position: absolute;
            bottom: 0;
          }

          @media (max-width: 1279px) {
            position: absolute;
            margin: 0;
            right: -20px;
            width: 200px;
            bottom: 0;

            img {
              left: 50%;
              width: 100%;
              transform: translate(-50%, 0);
            }
          }
        }

        &__right {
          display: flex;
          flex-direction: column;
          justify-content: center;

          &--title {
            @include font(28px, $c-white-l, 500, 36px);

            @media (max-width: 1279px) {
              font-size: 22px;
              line-height: 28px;
            }
          }

          &--subtitle {
            margin-top: 6px;
            @include font(18px, $c-white-l, 400, 23px);

            @media (max-width: 1279px) {
              width: 152px;
              font-size: 16px;
              line-height: 20px;
            }
          }

          .btn {
            margin-top: 18px;
            width: fit-content !important;
          }

          @media (max-width: 1279px) {
            br {
              display: none;
            }
          }
        }

      }
    }

    &--learn {
      margin-top: 120px;
      width: 100%;

      .learn {
        display: flex;
        flex-direction: column;

        &--title {
          @include font(28px, $c-darkest-navy, 500, 36px);
        }

        &--subtitle {
          margin-top: 12px;
          @include font(18px, $c-darkest-navy, 400, 23px);
        }

        &--cards {
          margin-top: 36px;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          gap: 24px;

          @media (max-width: 1279px) {
            display: flex;
            flex-direction: column;
            gap: 16px;

            &-item {
              &__head {
                img {
                  width: 100%;
                  height: auto;
                }
              }
            }
          }

          &-item {
            display: flex;
            flex-direction: column;
            border-radius: 4px;
            overflow: hidden;

            &__head {
              overflow: hidden;

              img {
                height: 100%;
              }
            }

            &__body {
              display: flex;
              flex-direction: column;
              padding: 32px;
              background: $c-white-l;

              &--title {
                @include font(22px, $c-dark-navy, 500, 28px);
              }

              &--subtitle {
                @include font(16px, $c-dark-navy, 400, 20px);
                margin-top: 16px;
              }

              &--footer {
                display: flex;
                align-items: center;
                gap: 8px;
                margin-top: 24px;
                @include font(16px, $c-dark-navy, 500, 20px);

                .chevron {
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    padding: 90px 0;

    @media (max-width: 1279px) {
      padding: 60px 20px;
    }

    &__start {
      .start {
        display: flex;
        width: 100%;
        background: $c-dark-navy;

        @media (max-width: 1279px) {
          display: flex;
          flex-direction: column-reverse;
          border-radius: 4px;
          overflow: hidden;
        }

        &__left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 64px;
          margin-right: 90px;

          @media (max-width: 1279px) {
            margin: 0;
            padding: 24px;
          }

          &--title {
            @include font(28px, $c-white-l, 500, 36px);

            @media (max-width: 1279px) {
              br {
                display: none;
              }
            }
          }

          &--subtitle {
            margin-top: 12px;
            @include font(18px, $c-white-l, 400, 23px);
          }

          &--info {
            display: flex;
            flex-direction: column;
            margin-top: 24px;
            gap: 12px;

            &-item {
              display: flex;
              align-items: center;
              gap: 6px;
              @include font(16px, $c-white-l, 400, 19px);
            }
          }

          .btn {
            margin-top: 24px;
            width: fit-content;
          }
        }

        &__right {
          overflow: hidden;
          margin-left: auto;

          @media (max-width: 1279px) {
            position: relative;
            width: 100%;
            height: 200px;
            overflow: hidden;
            margin: 0;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            &::after {
              content: "";
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              height: 60%;
              background: linear-gradient(to bottom, rgba(18, 37, 70, 0) 0%, rgb(18, 37, 70) 100%);
            }
          }

          img {
            height: 100%;
          }
        }
      }
    }

    &__questions {
      margin-top: 120px;

      .questions {
        display: flex;
        flex-direction: column;

        &__title {
          @include font(28px, $c-darkest-navy, 500, 36px);
        }

        &__list {
          display: flex;
          flex-direction: column;
          gap: 16px;
          margin-top: 32px;

          .spacer {
            width: 100%;
            height: 1px;
            background: #E3E6E9;
          }

          &--item {
            display: flex;
            flex-direction: column;

            &--head {
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;
              margin-bottom: 16px;

              @include font(18px, $c-dark-navy, 400, 23px);
            }

            &--body {
              margin-bottom: 16px;
              @include font(16px, $c-medium-navy, 400, 20px);
              overflow: hidden;
            }

          }
        }

      }
    }

    &__quote {
      margin-top: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 24px;
      background: #C4D7E7;
      position: relative;
      padding: 62px;

      @media (max-width: 1279px) {
        padding: 24px;
      }

      &--text {
        text-align: center;
        margin: auto;
        max-width: 780px;
        @include font(22px, $c-darkest-navy, 400, 28px);

        .bold {
          font-weight: 500;
        }
      }

      &--logo {
        position: absolute;
        opacity: 0.2;
        right: 0;
        bottom: 0;

        @media (max-width: 1279px) {
          width: 100px;
          height: 100px;
        }
      }
    }
  }
}

</style>
